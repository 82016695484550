// TicketStore.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TicketStorePage from './TicketStorePage'; // Import the TicketStorePage component
import { API_ENDPOINTS } from '../config';

const TicketStore = () => {
  const [event, setEvent] = useState(null);
  const searchParams = new URLSearchParams(useLocation().search);
  const eventID = searchParams.get('eventID');

  useEffect(() => {
    console.log('Event ID:', eventID); // Log eventID to the console
    // Fetch event details using the eventID
    //fetch(`events/${eventID}`)
    //fetch(`http://localhost:3010/events/${eventID}`)
    fetch(API_ENDPOINTS.getEvent(eventID))
      .then(response => response.json())
      .then(data => {
        setEvent(data); // Set the event state with the fetched event details
      })
      .catch(error => console.error('Error fetching event details:', error));
  }, [eventID]);

  // Render the TicketStorePage component and pass the event data to it
  return <TicketStorePage event={event} />;
};

export default TicketStore;
