import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../react-auth-app/auth';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import CreateEvent from '../create-event/CreateEvent'; // Import CreateEvent component
import './UserPanel.css'; // Custom CSS for additional styling
import { API_ENDPOINTS } from '../config';

const UserPanel = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [organizedEvents, setOrganizedEvents] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showCreateEventForm, setShowCreateEventForm] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');
    if (userId && accessToken) {
      fetchUserData(userId);
    } else {
      navigate('/sign-in');
    }
  }, [navigate]);

  //const url = API_ENDPOINTS.getTickets('${userId}');
  //console.log('Fetching tickets from URL:', url); // Log the URL
  //console.log(${userId})
  //

  //const url2 = API_ENDPOINTS.getTickets('userId');
  //console.log('Fetching tickets from URL:', url2); // Log the URL

  const fetchUserData = async (userId) => {
    
    try {

      //const response = await fetchWithAuth(`http://localhost:3010/user/${userId}`);
      const response = await fetchWithAuth(API_ENDPOINTS.getUser(userId));
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/sign-in');
    }
  };

  useEffect(() => {
    if (userData) {
      fetchTickets(userData.id);
      fetchOrganizedEvents(userData.id);
    }
  }, [userData]);

  const fetchTickets = async (userId) => {
    try {
//      const response = await fetchWithAuth(`http://localhost:3010/user/${userId}/tickets`);
      const response = await fetchWithAuth(API_ENDPOINTS.getTickets(userId));
      const data = await response.json();
      setTickets(data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const fetchOrganizedEvents = async (userId) => {
    try {
      //const response = await fetchWithAuth(`http://localhost:3010/user/${userId}/organized-events`);
      const response = await fetchWithAuth(API_ENDPOINTS.organizedEvents(userId));
      const data = await response.json();
      setOrganizedEvents(data);
    } catch (error) {
      console.error('Error fetching organized events:', error);
    }
  };

  const handleCreateEvent = () => {
    setShowCreateEventForm(true);
  };

  const handleShowPurchases = () => {
    setShowCreateEventForm(false);
  };

  const handleSignOut = () => {
    setUserData(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    document.cookie = 'refreshToken=; Max-Age=0; path=/; domain=' + window.location.hostname;
    navigate('/sign-in');
  };

  const handleEventCreated = () => {
    if (userData) fetchOrganizedEvents(userData.id);
    setShowCreateEventForm(false);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="shadow-lg p-2">
            <Card.Body>
              <h2 className="card-title text-center">User Panel</h2>
              {userData && (
                <>
                  <p className="mb-0">Welcome <strong>{userData.firstname} {userData.lastname}</strong>!</p>
                  <p className="mb-3">Email: {userData.email}</p>
                </>
              )}
              <div className="row mb-3 button-group">
                <div className="col">
                  <Button variant="outline-primary" className="w-100 button-equal-height" onClick={handleCreateEvent}>Create Event</Button>
                </div>
                <div className="col">
                  <Button variant="outline-info" className="w-100 button-equal-height" onClick={handleShowPurchases}>Show Purchases</Button>
                </div>
                <div className="col">
                  <Button variant="outline-warning" className="w-100 button-equal-height" onClick={handleSignOut}>Sign Out</Button>
                </div>
              </div>
              {showCreateEventForm ? (
                <CreateEvent userData={userData} onEventCreated={handleEventCreated} />
              ) : (
                <div>
                  {userData && (
                    <div>
                      <h5>Purchased Tickets</h5>
                      <div className="table-responsive">
                        <Table striped bordered hover className="table-fluid mb-5">
                          <thead className="bg-light table-active">
                            <tr>
                              <th>Event</th>
                              <th>Ticket Type</th>
                              <th>Seat ID</th>
                              <th>Purchase Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tickets.map((ticket, index) => (
                              <tr key={ticket.id} className={index === 0 ? 'bg-primary text-white' : ''}>
                                <td>{ticket.EventName}</td>
                                <td>{ticket.TicketType}</td>
                                <td>{ticket.seatID}</td>
                                <td>{ticket.purchaseTime}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <h5>Organized Events</h5>
                      <div className="table-responsive">
                        <Table striped bordered hover className="table-fluid">
                          <thead className="bg-light table-active">
                            <tr>
                              <th>Event Name</th>
                              <th>Venue Name</th>
                              <th>Event City</th>
                              <th>Event Country</th>
                              <th>Event Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {organizedEvents.map((event, index) => (
                              <tr key={event.id} className={index === 0 ? 'bg-primary text-white' : ''}>
                                <td>{event.EventName}</td>
                                <td>{event.VenueName}</td>
                                <td>{event.EventCity}</td>
                                <td>{event.EventCountry}</td>
                                <td>{event.EventDate}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPanel;
  