import React from 'react';
import { Container, Row, Col, Card, Carousel, Image, Button } from 'react-bootstrap';
import eventHostingImage1 from './assets/event-hosting1.jpg';
import eventHostingImage2 from './assets/event-hosting2.jpg';
import eventHostingImage3 from './assets/event-hosting3.jpg';
import eventHostingImage4 from './assets/event-hosting4.jpg';
import eventHostingImage5 from './assets/event-hosting5.jpg';
import eventHostingImage6 from './assets/event-hosting6.jpg';
import eventHostingImage7 from './assets/event-hosting7.jpg';
import eventHostingImage8 from './assets/event-hosting8.jpg';
import './EventHostingPage.css'; // Import custom CSS if needed

const EventHostingPage = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="shadow-lg mb-4">
            <Card.Body>
              <div className="text-center mb-4">
                <h1>Host Your Event with EventTop</h1>
                <p className="lead">Bring your vision to life with our comprehensive event-hosting solutions. From intimate gatherings to grand festivals, we’ve got you covered.</p>
              </div>

              <div className="carousel-wrapper carousel-controls-outside">
                <Carousel className="mx-auto">
                  <Carousel.Item>
                    <Image src={eventHostingImage1} alt="Event Hosting 1" fluid />
                    <Carousel.Caption>
                      <h3>Host Unforgettable Events</h3>
                      <p>Make your event a success with our extensive planning and hosting tools.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage7} alt="Event Hosting 2" fluid />
                    <Carousel.Caption>
                      <h3>Connect with Your Audience</h3>
                      <p>Reach a wider audience and engage them like never before.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage3} alt="Event Hosting 3" fluid />
                    <Carousel.Caption>
                      <h3>Streamline Your Event Management</h3>
                      <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage6} alt="Event Hosting 4" fluid />
                    <Carousel.Caption>
                      <h3>Streamline Your Event Management</h3>
                      <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image src={eventHostingImage5} alt="Event Hosting 5" fluid />
                    <Carousel.Caption>
                      <h3>Streamline Your Event Management</h3>
                      <p>Efficiently manage every aspect of your event from one platform.</p>
                    </Carousel.Caption>
                  </Carousel.Item>
    <Carousel.Item>
    <Image src={eventHostingImage4} alt="Event Hosting 6" fluid />
    <Carousel.Caption>
    <h3>Streamline Your Event Management</h3>
    <p>Efficiently manage every aspect of your event from one platform.</p>
    </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <Image src={eventHostingImage7} alt="Event Hosting 7" fluid />
    <Carousel.Caption>
    <h3>Streamline Your Event Management</h3>
    <p>Efficiently manage every aspect of your event from one platform.</p>
    </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <Image src={eventHostingImage2} alt="Event Hosting 8" fluid />
    <Carousel.Caption>
    <h3>Streamline Your Event Management</h3>
    <p>Efficiently manage every aspect of your event from one platform.</p>
    </Carousel.Caption>
    </Carousel.Item>
    
    </Carousel>
              </div>

              <div className="mt-5">
                <h2>Why Choose EventTop for Your Event Hosting?</h2>
                <p>EventTop offers a robust platform that simplifies event hosting and enhances the experience for both hosts and attendees. Here’s what you can expect:</p>
                
                <Row className="text-center mb-4">
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage1} alt="Event Creation" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage6} alt="Advanced Ticketing" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage8} alt="Real-Time Analytics" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage3} alt="Marketing Tools" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage4} alt="Payment Options" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage5} alt="Customizable Pages" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage7} alt="Secure Platform" fluid className="mb-4" />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Image src={eventHostingImage2} alt="Customer Support" fluid className="mb-4" />
                  </Col>
                </Row>

                <h3>1. Seamless Event Creation</h3>
                <p>Our intuitive interface allows you to create and customize your event page with ease. Add all the necessary details, from venue information to ticket pricing, in just a few clicks.</p>

                <h3>2. Advanced Ticketing System</h3>
                <p>Manage ticket sales and distribution efficiently with our integrated ticketing system. Offer various ticket types and price tiers to cater to different audience segments.</p>

                <h3>3. Real-Time Analytics</h3>
                <p>Track your event’s performance with real-time analytics. Gain insights into ticket sales, attendee demographics, and more to make data-driven decisions.</p>

                <h3>4. Comprehensive Marketing Tools</h3>
                <p>Promote your event using our suite of marketing tools. Reach a larger audience through email campaigns, social media integration, and SEO optimization.</p>

                <h3>5. Flexible Payment Options</h3>
                <p>Offer a variety of payment methods to accommodate your attendees’ preferences. Our secure payment gateway ensures a safe transaction process.</p>

                <h3>6. Customizable Event Pages</h3>
                <p>Personalize your event page to reflect your brand and event theme. Add images, videos, and custom text to engage your audience.</p>

                <h3>7. Exceptional Customer Support</h3>
                <p>Our dedicated support team is here to help you every step of the way. From setting up your event to handling any issues, we’ve got you covered.</p>

                <h3>8. Secure and Reliable Platform</h3>
                <p>EventTop provides a secure platform that protects your data and ensures the smooth running of your event. Rely on us for a hassle-free event hosting experience.</p>

                <h2 className="mt-5">Testimonials</h2>
                <div className="mb-4">
                  <Card className="mb-3">
                    <Card.Body>
                      <blockquote className="blockquote mb-0">
                        <p>“Hosting our event on EventTop was a game-changer. The platform is user-friendly, and the support team is incredibly helpful. We managed to reach a broader audience and had a fantastic turnout!”</p>
                        <footer className="blockquote-footer">Leyla Aliyeva, Event Organizer</footer> {/* Azerbaijani name */}
                      </blockquote>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3">
                    <Card.Body>
                      <blockquote className="blockquote mb-0">
                        <p>“EventTop’s tools made it easy to manage ticket sales and track our event’s performance. The analytics provided valuable insights that helped us make informed decisions.”</p>
                        <footer className="blockquote-footer">Murat Yılmaz, Event Organizer</footer> {/* Turkish name */}
                      </blockquote>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3">
                    <Card.Body>
                      <blockquote className="blockquote mb-0">
                        <p>“Thanks to EventTop, our cultural festival was a huge success. The platform’s features made everything so much easier, from ticketing to audience engagement.”</p>
                        <footer className="blockquote-footer">Sara Mirzai, Festival Organizer</footer> {/* Iranian name */}
                      </blockquote>
                    </Card.Body>
                  </Card>
                </div>

                <div className="text-center mb-4">
                  <Button href="/registration-form" className="btn-lg" variant="primary">Start Hosting Your Event</Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EventHostingPage;
