import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import RegistrationForm from './react-auth-app/RegistrationForm';
import SignInForm from './react-auth-app/SignInForm';
import UserPanel from './react-auth-app/UserPanel';
import EventList from './event-showcase/EventList';
import TicketStorePage from './ticket-store/TicketStorePage';
import TicketStore from './ticket-store/TicketStore';
import CreateEvent from './create-event/CreateEvent';
import ImageUploadForm from './create-event/ImageUploadForm';
import UserBadge from './react-navbar/UserBadge';
import ReactNavbar from './react-navbar/navbar';
import ReactHeader from './react-header/header';
import AboutUsPage from './AboutUsPage';
import ContactUsPage from './ContactUsPage';
import Careers from './CareersPage';
import TermsOfUse from './TermsOfUsePage';
import PrivacyPolicy from './PrivacyPolicyPage.js';
import Footer from './react-footer/react-footer'; // Import the Footer component
import EventHostingPage from './EventHostingPage.js'; // Import the Footer component
import TicketSalesPage from './TicketSalesPage.js'; // Import the Footer component
import { API_ENDPOINTS } from './config';

import './index.css';

const App = () => {
  const [userData, setUserData] = useState(() => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    return accessToken && userId ? { accessToken, userId } : null;
  });

  useEffect(() => {
    if (userData && userData.accessToken) {
      fetchUserData(userData.accessToken, userData.userId);
    }
  }, [userData]);

  const fetchUserData = async (token, userId) => {
    try {
      //const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
      const response = await fetch(API_ENDPOINTS.getUser(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      setUserData({ ...userData, ...data });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleSignOut = () => {
    setUserData(null); // This will trigger a re-render of components that depend on userData
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    document.cookie = 'refreshToken=; Max-Age=0; path=/; domain=' + window.location.hostname;
  };
  
  return (
    <Router>
      <div className="container-fluid p-0">
        <ReactNavbar user={userData} />
        <ReactHeader user={userData} />
        <Routes>
          <Route path="/registration-form" element={<RegistrationForm />} />
          <Route path="/sign-in" element={<SignInForm setUserData={setUserData} />} />
          <Route path="/events" element={<EventList />} />
          <Route path="/ticket-store" element={<TicketStore />} />
          <Route path="/ticket-store" element={<TicketStorePage />} />
          <Route path="/userpanel" element={<UserPanel userData={userData} />} />
          <Route path="/create-event" element={<CreateEvent />} />
    //          <Route path="/Image-Upload-Form" element={<ImageUploadForm />} />
          <Route path="/" element={<Navigate to="/events" />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/EventHostingPage" element={<EventHostingPage />} />
          <Route path="/TicketSalesPage" element={<TicketSalesPage />} />
          <Route path="*" element={<div>404 Page Not Found</div>} />
        </Routes>
        <Footer /> {/* Add the Footer component */}
      </div>
    </Router>
  );
};

export default App;
  