// ContactUs.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ContactUsPage = () => {
  return (
    <Container className="mt-5">
    <Row className="justify-content-center">
    <Col md={12}>
    <Card className="shadow-lg">
    <Card.Body>
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <main className="px-3">
    <h1>Contact Us!</h1>
    <div className="float p-3 border rounded bg-light">
    <p className="lead">🌐 Welcome to our world of vibrant events! 🎉 We are EventTop, your go-to online ticketing platform based in Canada, dedicated to bringing the global celebration to your fingertips.</p>
    <p className="lead">🌍 Our mission is to promote and connect communities, with a special focus on events that showcase the rich cultural tapestry of Azerbaijani, Turkish, and other Turkic communities around the world.</p>
    </div>
    
    <div className="float p-3 mt-1 border rounded bg-light">
    <p className="lead">🎭 Toronto, ON, Canada</p>
    <p className="lead">&#x1F3AD; Contact email: <a href="mailto:Info@EventTop.ca" className="email">&#73;&#110;&#102;&#111;&#64;&#69;&#118;&#101;&#110;&#116;&#84;&#111;&#112;&#46;&#99;&#97;</a></p>
    <p className="lead">🎭 Contact phone: 438-924-2364 (9 AM - 5:30 PM EST)</p>
    </div>
    
    <div className="float p-3 mt-1 border rounded bg-light">
    <h2 className="lead text-center" style={{ fontSize: "2em" }}>Event Hosting on EventTop</h2>
    <p>📅 Looking to host an event? At EventTop, we offer comprehensive solutions to make your event planning a breeze. Whether it's a cultural festival, a corporate seminar, or a community gathering, our platform is equipped to support events of all sizes and types. </p>
                    <p>🔹 <strong>Easy Setup:</strong> Quickly create and manage your event with our user-friendly interface.</p>
                    <p>🔹 <strong>Wide Reach:</strong> Connect with a broad audience and maximize your event's visibility.</p>
    <p>🔹 <strong>Advanced Tools:</strong> Utilize our advanced tools for ticketing, seating arrangements, and more.</p>
    <p>Ready to host? Visit our <a href="/EventHostingPage" className="text-primary">Event Hosting</a> page to get started!</p>
    </div>
    
    <div className="float p-3 mt-1 border rounded bg-light">
    <h2 className="lead text-center" style={{ fontSize: "2em" }}>Create Your Event on EventTop</h2>
    <p>🎨 Creating an event on EventTop is simple and intuitive. Our platform guides you through every step, from setting up event details to publishing and promoting your event.</p>
    <p>🔹 <strong>Customizable Options:</strong> Tailor your event's page to reflect its unique personality and purpose.</p>
                    <p>🔹 <strong>Integrated Marketing:</strong> Leverage our marketing tools to spread the word and boost attendance.</p>
                    <p>🔹 <strong>Comprehensive Analytics:</strong> Gain insights with real-time analytics to monitor your event's performance.</p>
    <p>Start creating your event today by visiting our <a href="/registration-form" className="text-primary">Event Creation</a> page.</p>
    </div>
    
    <div className="float p-3 mt-1 border rounded bg-light">
    <h2 className="lead text-center" style={{ fontSize: "2em" }}>EventTop Motto in Different Languages</h2>
    <ul className="list-unstyled">
    <li><span className="fw-bold">Turkish:</span> "Event'ini Top, Ticket'ini Al"</li>
    <li><span className="fw-bold">Spanish:</span> "Encuentra tu evento, obtén tu entrada"</li>
    <li><span className="fw-bold">English:</span> "Find your event, get your ticket"</li>
    <li><span className="fw-bold">French:</span> "Trouvez votre événement, achetez votre billet"</li>
    <li><span className="fw-bold">German:</span> "Finde dein Event, hol dir dein Ticket"</li>
    <li><span className="fw-bold">Italian:</span> "Trova il tuo evento, prendi il tuo biglietto"</li>
    <li><span className="fw-bold">Portuguese:</span> "Encontre o seu evento, compre o seu ingresso"</li>
    <li><span className="fw-bold">Persian:</span> "رویداد خود را پیدا کنید، بلیط خود را بگیرید"</li>
    <li><span className="fw-bold">Azerbaijani:</span> "Tədbirinizi tapın, biletinizi alın"</li>
    <li><span className="fw-bold">Russian:</span> "Найдите свое мероприятие, получите билет"</li>
    <li><span className="fw-bold">Arabic:</span> "ابحث عن حدثك ، احصل على تذكرتك"</li>
    <li><span className="fw-bold">Chinese (Simplified):</span> "找到您的活动，购买您的门票"</li>
    <li><span className="fw-bold">Japanese:</span> "あなたのイベントを見つけて、あなたのチケットを手に入れてください" (Anata no ibento o mitsukete, anata no chiketto o te ni irete kudasai)</li>
    <li><span className="fw-bold">Hindi:</span> "अपनी घटना ढूंढें, अपना टिकट प्राप्त करें" (Apnī ghaṭnā ḍhūṅḍheṁ, apnā ṭikaṭ prāpt kareṁ)</li>
    </ul>
    </div>
    </main>
    </div>
    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>
  );
};

export default ContactUsPage;

  