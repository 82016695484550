// ReactNavbar.js

import React, { useState } from 'react';
import { Navbar, Nav, Container, Form, FormControl, Row, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import './ReactNavbar.css'; // Import custom CSS for additional styling
import { API_ENDPOINTS } from '../config';


const ReactNavbar = ({ user, onSignOut }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  
  const navigate = useNavigate();
  
  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    if (typeof onSignOut === 'function') {
      onSignOut();
    }
    navigate('/sign-in'); // Navigate to the sign-in page
  };
  
  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const searchData = Object.fromEntries(formData.entries());
    
    try {
      //const response = await fetch('http://localhost:3010/eventsearch', {
      const response = await fetch(API_ENDPOINTS.eventSearch, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchData),
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }
      
      const data = await response.json();
      setSearchResults(data.slice(0, 5)); // Limit to 5 results
      setSearchClicked(true);
    } catch (error) {
      console.error('Error searching:', error);
    }
  };
  
  const handleBuyTickets = (eventID) => {
    navigate(`/ticket-store?eventID=${eventID}`);
  };
  
  return (
    <div>
    <Navbar bg="dark" variant="dark" expand="lg" className="p-3">
    <Container fluid>
    <Navbar.Brand href="https://eventtop.ca">
    <img src="images/Logo6.jpg" alt="EventTop Logo" style={{ width: '150px', height: 'auto' }} />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarCollapse" />
    <Navbar.Collapse id="navbarCollapse">
    <Nav className="mr-auto flex-grow-1">
    <Nav.Link as={Link} to="/events" className="nav-link">Events</Nav.Link>
    <Nav.Link as={Link} to="/about-us" className="nav-link">About Us!</Nav.Link>
    <Nav.Link as={Link} to="/contact-us" className="nav-link">Contact Us!</Nav.Link>
    <Nav.Link as={Link} to="/careers" className="nav-link">Career Opportunities</Nav.Link>
    <Nav.Link as={Link} to="/create-event" className="nav-link">Create Event</Nav.Link>
    </Nav>
    </Navbar.Collapse>
    <div className="d-flex align-items-center">
    {user ? (
      <div className="text-info d-flex align-items-center">
      <Link to="/userpanel" className="btn btn-link me-0"><strong>Welcome, {user.firstname}!</strong></Link>
      <button className="btn btn-link" onClick={handleSignOut}>Sign Out</button>
      </div>
    ) : (
      <Nav.Link as={Link} to="/sign-in" className="nav-link sign-in-link">Sign In/Register</Nav.Link>
    )}
    </div>
    </Container>
    </Navbar>
    
    <Navbar bg="dark" variant="dark" expand="lg" className="mr-auto">
    <Container>
    <Navbar.Collapse id="navbarSearchCollapse">
    <Form onSubmit={handleSearchSubmit} className="d-flex w-100 justify-content-center">
    <FormControl type="text" name="event_location" placeholder="Event City" aria-label="Event Location" className="me-2" />
    <FormControl type="text" name="event_name" placeholder="Event Name" aria-label="Event Name" className="me-2" />
    <Button variant="outline-success" type="submit">Search</Button>
    </Form>
    </Navbar.Collapse>
    </Container>
    </Navbar>
    
    {searchClicked && (
      <Row className="justify-content-center p-md-3 mx-auto">
      <Container className="p-0 p-md-0 mb-0 border rounded bg-light">
      <div className="bg-light-gray p-0 rounded justify-content-center">
      {searchResults.length > 0 ? (
        <ul>
        {searchResults.map((result) => (
          <li key={result.EventID} onClick={() => handleBuyTickets(result.EventID)} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>
          {result.EventName} - {result.EventCity} - {result.EventCountry}
          </li>
        ))}
        </ul>
      ) : (
        <p>No results found.</p>
      )}
      </div>
      </Container>
      </Row>
    )}
    </div>
  );
};

export default ReactNavbar;
  