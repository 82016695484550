// TicketStorePage.js

// TicketStorePage.js

import React from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../config';

const TicketStorePage = ({ event }) => {
  const navigate = useNavigate();
  
  // Check if event is null before accessing its properties
  if (!event) {
    return <div>Loading...</div>; // Render loading state while waiting for event data
  }
  
  const handlePurchase = () => {
    // Logic for handling ticket purchase
    navigate('/SeatMap');
  };
  
  // Construct the Google Maps URL with the venue address
  const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAS8IoH8aEfCgmsZeS7HmEuP3M783tnipY&q=${encodeURIComponent(
    event.VenueAddress
  )}`;
  
  // Determine the event poster image path
  const posterImagePath = event.PosterImagePath
  ? event.PosterImagePath.startsWith(API_ENDPOINTS.uploads)
  ? `${API_ENDPOINTS.uploads}${event.PosterImagePath}`
  : `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
  : `${API_ENDPOINTS.uploads}/placeholder_image.png`; // Use a default placeholder image if path is missing
  
  return (
    <>
    <Row className="justify-content-center p-md-3 mx-auto bg-shadow">
    {/* first empty row */}
    <Container className="p-2 p-md-3 mb-0 border rounded bg-light">
    <h1 className="text-center">Event Store</h1>
    <div className="bg-light-gray p-0 rounded">
    <p className="mb-0"><strong>Event Name:</strong> {event.EventName}</p>
    <p className="mb-3"><strong>Event Type:</strong> {event.EventType}</p>
    <p className="mb-0"><strong>Organizer:</strong> {event.OrganizerName}</p>
    <p className="mb-0"><strong>Event City/Country:</strong> {event.EventCity}, {event.EventCountry}</p>
    <p className="mb-0"><strong>Date/Time:</strong> {new Date(event.EventDate).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })}, {event.StartTime} - {event.EndTime}</p>
    <p className="mb-0"><strong>Venue:</strong> {event.VenueName}</p>
    <p className="mb-3"><strong>Venue Address:</strong> {event.VenueAddress}</p>
    <p className="mb-0"><strong>Theme:</strong> {event.Theme}</p>
    <p className="mb-0"><strong>Dress Code:</strong> {event.DressCode}</p>
    <p className="mb-2"><strong>Accessibility Info:</strong> {event.AccessibilityInfo}</p>
    {/* Render additional event details here */}
    
    <div className="bg-light-gray p-1 rounded text-center">
    <Button variant="primary" className="mx-auto" onClick={handlePurchase} style={{ width: '80%' }}>
    Purchase Tickets
    </Button>
    {/* Render additional event details here */}
    </div>
    </div>
    </Container>
    </Row>
    
    <Row className="justify-content-center p-md-3 mx-auto">
    {/* Secondary empty row */}
    <h4 className="text-center">Event Poster</h4>
    <Container className="p-0 p-md-0 mb-0 border rounded bg-light">
    <div className="bg-light-gray p-0 rounded justify-content-center text-center">
    <img
    src={posterImagePath}
    alt="Event Poster"
    className="img-fluid mx-auto d-block rounded"
    onError={(e) => { e.target.onerror = null; e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`; }} // Fallback image
    />
    {/* Render additional event details here */}
    </div>
    </Container>
    </Row>
    
    {event.seatmap === 1 && (
      <Row className="justify-content-center p-md-3 mx-auto">
      {/* Seat Map row, only visible if seatmap is 1 */}
      <h4 className="text-center">Seat Map</h4>
      <Container className="p-0 p-md-0 mb-3 border rounded bg-light">
      <div className="bg-light-gray p-0 rounded">
      <img
      src="https://via.placeholder.com/350"
      alt="Seat Map"
      className="img-fluid mx-auto d-block rounded"
      />
      </div>
      </Container>
      </Row>
    )}
    
    <Row className="justify-content-center p-md-3 mx-auto">
    {/* Fourth empty row */}
    <h4 className="text-center">Venue Location</h4>
    <Container className="p-0 p-md-0 mb-3 border rounded bg-light">
    <div className="bg-light-gray p-0 rounded justify-content-center text-center">
    <iframe
    title="Google Maps"
    width="100%"
    height="400"
    loading="lazy"
    allowFullScreen
    className="rounded"
    src={mapsUrl}
    ></iframe>
    {/* Render additional event details here */}
    </div>
    </Container>
    </Row>
    
    <Row className="justify-content-center p-md-3 mx-auto">
    {/* Fifth empty row */}
    <Container className="p-0 p-md-1 mb-0 border rounded bg-light">
    <div className="bg-light-gray p-0 rounded text-center">
    <Button variant="primary" className="mx-auto" onClick={handlePurchase} style={{ width: '80%' }}>
    Purchase Tickets
    </Button>
    {/* Render additional event details here */}
    </div>
    </Container>
    </Row>
    </>
  );
};

export default TicketStorePage;
  