import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ReactHeader = () => {
  return (
    <main className="container-fluid p-0">
      {/* TOP Header */}
      <div className="p-1 p-md-5  text-light bg-body-secondary" style={{backgroundImage: "url('images/header-background-2.png')", backgroundSize: 'cover', backgroundPosition: 'center', height: '500px'}}>
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={15} className="px-2 my-4">
              <h1 className="display-4 fw-bold fst-italic rounded text-shadow yeserva-font">Host your Events With Us</h1>
              <h3 className="bounce text-lg creepster-font text-shadow kavoon-font">
                <span className="top-text">Top Events,</span> {/* bright-text, events-text, bright-text with-text */}
                <span className="tickets-text">Top Tickets,</span>
                <span className="with-text"> with </span>
                <span className="eventtop-text">EventTop!</span>
              </h3>
              <h3 className="my-4">
              {/*  <span className="turkish-text">Event'i Top, Ticket'ini Al</span> */}
              </h3>
              {/* <p className="lead mb-0"><a href="#" className="text-light fw-bold">Continue reading...</a></p> */}
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default ReactHeader;
  