import React from 'react';
  import { Container, Row, Col, Card, Carousel, Image } from 'react-bootstrap';
  import ticketSalesImage1 from './assets/ticket-sales1.jpg'; // Add appropriate paths for images
  import ticketSalesImage2 from './assets/ticket-sales2.jpg';
  import ticketSalesImage3 from './assets/ticket-sales3.jpg';
  import ticketSalesImage4 from './assets/ticket-sales4.jpg';
  import './TicketSalesPage.css'; // Import custom CSS for additional styling
  
  const TicketSalesCarousel = () => {
    return (
      <Container className="mt-5">
      <Row className="justify-content-center">
      <Col md={12}>
      <Card className="shadow-lg mb-4">
      <Card.Body>
      <div className="text-center mb-4">
      <h1>Sell Your Tickets with EventTop</h1>
      <p className="lead">Maximize your event's success with EventTop.ca's comprehensive ticketing solutions.</p>
      </div>
      
      <Carousel className="carousel-wrapper mx-auto">
      <Carousel.Item>
      <Image src={ticketSalesImage1} alt="Seamless Event Creation" fluid />
      <Carousel.Caption>
      <h3>Seamless Event Creation</h3>
      <p>Our intuitive interface allows you to create and customize your event page with ease.</p>
      </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <Image src={ticketSalesImage2} alt="Advanced Ticketing System" fluid />
      <Carousel.Caption>
      <h3>Advanced Ticketing System</h3>
      <p>Manage ticket sales and distribution efficiently with our integrated ticketing system.</p>
      </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <Image src={ticketSalesImage3} alt="Real-Time Analytics" fluid />
      <Carousel.Caption>
      <h3>Real-Time Analytics</h3>
      <p>Track your event’s performance with real-time analytics.</p>
      </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <Image src={ticketSalesImage4} alt="Comprehensive Marketing Tools" fluid />
      <Carousel.Caption>
      <h3>Comprehensive Marketing Tools</h3>
      <p>Promote your event using our suite of marketing tools.</p>
      </Carousel.Caption>
      </Carousel.Item>
      </Carousel>
      
      <div className="mt-5">
      <h2>Why Choose EventTop for Your Ticket Sales?</h2>
      <div className="feature-explanation">
      <h3>1. Seamless Event Creation</h3>
      <p>EventTop.ca offers a user-friendly platform that makes creating and customizing your event page a breeze. With just a few clicks, you can add all the necessary details, such as venue information, ticket pricing, and event schedules. This intuitive interface ensures that setting up your event is quick and hassle-free, allowing you to focus on what matters most—planning a successful event.</p>
      
      <h3>2. Advanced Ticketing System</h3>
      <p>Our advanced ticketing system is designed to streamline the ticket sales process. It supports various ticket types, from general admission to VIP packages, and allows for flexible pricing options. The secure checkout process ensures a smooth transaction experience for your attendees, helping you manage sales efficiently and effectively.</p>
      
      <h3>3. Real-Time Analytics</h3>
      <p>With EventTop.ca, you gain access to comprehensive real-time analytics that provide valuable insights into your ticket sales and attendee demographics. Monitor your event’s performance, track sales trends, and analyze customer data to make informed decisions that can enhance your event's success and profitability.</p>

                  <h3>4. Comprehensive Marketing Tools</h3>
                  <p>EventTop.ca offers a suite of powerful marketing tools to help you promote your event. From social media integration and email campaigns to SEO optimization, our platform enables you to reach a broader audience and drive ticket sales. Utilize these tools to create buzz and generate interest in your event, ensuring maximum visibility and attendance.</p>

                  <h3>5. Exceptional Customer Support</h3>
                  <p>Our dedicated support team is here to assist you every step of the way. Whether you need help setting up your event, managing ticket sales, or resolving any issues that arise, we are committed to providing top-notch support to ensure your event's success. Our team is always ready to offer guidance and solutions to help you achieve your event goals.</p>
      
      <h3>6. Secure and Reliable Platform</h3>
      <p>EventTop.ca is built on a secure and reliable platform that ensures the safety of your data and the smooth operation of your event. Our robust infrastructure and security measures protect your information and provide a seamless experience for you and your attendees. Trust EventTop.ca to handle your ticket sales with the utmost security and reliability.</p>
      </div>
      </div>
      
      
      
      </Card.Body>
      </Card>
      </Col>
      </Row>
      </Container>
    );
  };
  
  export default TicketSalesCarousel;
    