// TermsOfUsePage.js

import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

const TermsOfUse = () => {
  return (

    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <main className="px-3">
    
    <div class="container">

    <Row>
    <Col>
    <h1>Terms of Use Agreement</h1>
    <p>
    This Terms of Use Agreement ("Agreement") is a legally binding contract between you ("User" or "you") and <a href="https://eventtop.ca" target="_blank" rel="noopener noreferrer">https://eventtop.ca</a> (the “Site”) governing your use of the ticketing services provided through our website ("Services"). By accessing or using our Services, you agree to be bound by the terms and conditions of this Agreement. If you do not agree with these terms, please do not use our Services.
    </p>
    
    <h2>Changes to these Terms of Use</h2>
    <p>
    The Site reserves the right to make changes to these Terms of Use in whole or in part, at any time, at its sole discretion and without prior notice. The Site reserves the right to remove any information that you post, upload, publish, transmit, distribute or otherwise use on the Site, in whole or in part, at any time, at its sole discretion and without prior notice.
    </p>
    
    <h2>Acceptance of Terms</h2>
    <p>
    By using our Services, you acknowledge that you have read, understood, and agree to be bound by this Agreement and any additional terms and policies referenced herein.
    </p>
    
    <h2>Restrictions on Commercial Use</h2>
    <p>
    The contents featured on this website, encompassing text, information, designs, photographs, graphics, images, illustrations, interfaces, codes, audio clips, video clips, software, and links to external websites (collectively referred to as the "Content"), are exclusively intended for informational purposes. The utilization of this Content for any commercial purposes is strictly prohibited.
    </p>
    
    <h2>Eligibility</h2>
    <p>
    You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you are at least 18 years old.
    </p>
    
    <h2>Account Registration</h2>
    <p>
    To access certain features of our Services, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
    </p>
    
    <h2>Ticket Purchases</h2>
    <ListGroup variant="flush">
    <ListGroup.Item><b>a. Payment:</b> When purchasing tickets through our Services, you agree to provide accurate and complete payment information. You authorize us to charge your payment method for the total amount of your purchase, including any applicable fees.</ListGroup.Item>
    <ListGroup.Item><b>b. Refunds and Exchanges:</b> All ticket sales are final, and we do not offer refunds or exchanges unless otherwise specified. In the event of a cancellation or rescheduling of an event by the organizer, we will provide information on our website regarding the refund or exchange process.</ListGroup.Item>
    </ListGroup>
    
    <h2>User Conduct</h2>
    <p>
    You agree not to use our Services for any unlawful or prohibited purpose and to comply with all applicable laws and regulations. You further agree not to:
    </p>
    <ListGroup variant="flush">
    <ListGroup.Item>a. Violate any intellectual property rights.</ListGroup.Item>
    <ListGroup.Item>b. Interfere with or disrupt the Services.</ListGroup.Item>
    <ListGroup.Item>c. Engage in any fraudulent or deceptive activities.</ListGroup.Item>
    <ListGroup.Item>d. Use automated scripts to collect information from our website.</ListGroup.Item>
    </ListGroup>
    
    <h2>Intellectual Property</h2>
    <p>
    All content and materials available on our website, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of the Company or its licensors and are protected by intellectual property laws.
    </p>
    
    <h2>Privacy Policy</h2>
    <p>
    Your use of our Services is also governed by our Privacy Policy, which is incorporated into this Agreement by reference. Please review our Privacy Policy to understand our practices.
    </p>
    
    <h2>Limitation of Liability</h2>
    <p>
    To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
    </p>
    <ListGroup variant="flush">
    <ListGroup.Item>a. Your use or inability to use the Services.</ListGroup.Item>
    <ListGroup.Item>b. Any unauthorized access to or use of our servers and/or any personal information stored therein.</ListGroup.Item>
    </ListGroup>
    
    <h2>Termination</h2>
    <p>
    We reserve the right to terminate or suspend your account and access to the Services, without prior notice, for any reason, including a breach of this Agreement.
    </p>
    
    <h2>Changes to Terms</h2>
    <p>
    We reserve the right to modify or update this Agreement at any time, and such changes will be effective immediately upon posting. It is your responsibility to review this Agreement periodically for changes.
    </p>
    
    <h2>Prohibited Activities</h2>
    <p>
    You agree not to use the site for any unlawful activity or to infringe upon the rights of the site, its affiliates, or others. Specifically, you may not:
    </p>
    <ListGroup variant="flush">
    <ListGroup.Item>Interfere with the operation of the site.</ListGroup.Item>
    <ListGroup.Item>Disrupt anyone's use or enjoyment of the site.</ListGroup.Item>
            <ListGroup.Item>Harass anyone on or through the site.</ListGroup.Item>
            <ListGroup.Item>Impersonate any person or entity on the site.</ListGroup.Item>
            <ListGroup.Item>Post content or views that may be considered threatening, abusive, vulgar, obscene, or otherwise objectionable.</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

    </div>
    </main>
    </div>


  );
};

export default TermsOfUse;

  