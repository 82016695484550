import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer mt-3 py-3 bg-body-tertiary">
      <Container>
        <Row>
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-between">
              <Button href="https://www.facebook.com/profile.php?id=61555759665953&sk=about" target="_blank" variant="link">
                <img src="assets/icons/facebook.svg" alt="Facebook Icon" width="32" height="32" />
              </Button>
              <Button href="https://twitter.com/EventTop" target="_blank" variant="link">
                <img src="assets/icons/twitter-x.svg" alt="Twitter-X Icon" width="32" height="32" />
              </Button>
              <Button href="https://www.instagram.com/EventTop.ca" target="_blank" variant="link">
                <img src="assets/icons/instagram.svg" alt="Instagram Icon" width="32" height="32" />
              </Button>
              <Button href="mailto:info@eventtop.ca" target="_blank" variant="link">
                <img src="assets/icons/envelope-at.svg" alt="Email Icon" width="32" height="32" />
              </Button>
            </div>
            <span className="text-dark">All rights reserved for EventTop! &copy; 2024</span>
          </Col>
          <Col md={3}>
            <h5></h5>
            <ul className="list-unstyled">
              <li><Link className="link-dark" to="/careers">Careers</Link></li>
              <li><Link className="link-dark" to="/contact-us">Contact Us</Link></li>
              <li><Link className="link-dark" to="/payment-guide">Online Payment Guide</Link></li>
            </ul>
          </Col>
          <Col md={4} className="offset-md-1">
            <h5></h5>
            <ul className="list-unstyled">
              <li><Link className="link-dark" to="/TermsOfUse">Terms of Use</Link></li>
              <li><Link className="link-dark" to="/PrivacyPolicy">Privacy Policy</Link></li>
              <li><Link className="link-dark" to="/TicketSalesPage">Ticket Sale</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
