// AboutUsPage.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const AboutUsPage = () => {
  return (
    
    <Container className="mt-5">
    <Row className="justify-content-center">
    <Col md={12}>
    <Card className="shadow-lg">
    <Card.Body>

    
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <main className="px-3">
    <h1>About Us!</h1>

    <div className="float p-3 border rounded bg-light">

    <p className="lead">🌐 Welcome to our world of vibrant events! 🎉 We are EventTop, your go-to online ticketing platform based in Canada, dedicated to bringing the global celebration to your fingertips. 🌍 Our mission is to promote and connect communities, with a special focus on events that showcase the rich cultural tapestry of Azerbaijani, Turkish, and other Turkic communities around the world.</p>
    
    <p className="lead">🎭 Whether it's mesmerizing performances, immersive cultural experiences, or lively festivities, we strive to be the bridge that connects event organizers with enthusiastic attendees. From traditional celebrations to contemporary gatherings, we curate a diverse range of events that resonate with the spirit of togetherness and celebration.</p>

    <p className="lead">💻 With a user-friendly platform, we provide a seamless ticketing experience, making it easy for you to discover, book, and attend events that speak to your interests. As proud promoters of cultural diversity, we believe that every event is a unique story waiting to be told, and we're here to make sure you never miss a chapter.</p>
    
    <p className="lead">🌟 Join us in embracing the beauty of diversity and creating unforgettable memories. Whether you're an event organizer looking to reach a wider audience or an individual eager to explore the kaleidoscope of global cultures, EventTop is your virtual ticket to the world of celebrations. Let's make every event a moment to cherish!</p>
    
    </div>
    
    <div className="float p-3 mt-1 border rounded bg-light">
    
    <p className="lead text-center">EventTop Motto in Different Languages</p>
    
    <ul className="list-unstyled">
    <li><span className="fw-bold">Turkish:</span> "Event'ini Top, Ticket'ini Al"</li>
    <li><span className="fw-bold">Spanish:</span> "Encuentra tu evento, obtén tu entrada"</li>
    <li><span className="fw-bold">English:</span> "Find your event, get your ticket"</li>
    <li><span className="fw-bold">French:</span> "Trouvez votre événement, achetez votre billet"</li>
    <li><span className="fw-bold">German:</span> "Finde dein Event, hol dir dein Ticket"</li>
    <li><span className="fw-bold">Italian:</span> "Trova il tuo evento, prendi il tuo biglietto"</li>
    <li><span className="fw-bold">Portuguese:</span> "Encontre o seu evento, compre o seu ingresso"</li>
    <li><span className="fw-bold">Persian:</span> "رویداد خود را پیدا کنید، بلیط خود را بگیرید"</li>
    <li><span className="fw-bold">Azerbaijani:</span> "Tədbirinizi tapın, biletinizi alın"</li>
    <li><span className="fw-bold">Russian:</span> "Найдите свое мероприятие, получите билет"</li>
    <li><span className="fw-bold">Arabic:</span> "ابحث عن حدثك ، احصل على تذكرتك"</li>
    <li><span className="fw-bold">Chinese (Simplified):</span> "找到您的活动，购买您的门票"</li>
    <li><span className="fw-bold">Japanese:</span> "あなたのイベントを見つけて、あなたのチケットを手に入れてください" (Anata no ibento o mitsukete, anata no chiketto o te ni irete kudasai)</li>
    <li><span className="fw-bold">Hindi:</span> "अपनी घटना ढूंढें, अपना टिकट प्राप्त करें" (Apnī ghaṭnā ḍhūṅḍheṁ, apnā ṭikaṭ prāpt kareṁ)</li>
    </ul>
    
    
    </div>


    </main>
    </div>

    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>

    
  );
};

export default AboutUsPage;
  