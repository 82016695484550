// SignInForm.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { API_ENDPOINTS } from '../config'; // Adjust the path as necessary

const SignInForm = ({ setUserData }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      //      const response = await fetch('http://localhost:3010/login', {
      const response = await fetch(API_ENDPOINTS.login, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
        credentials: 'include' // Include credentials in the request
      });
      
      if (!response.ok) {
        throw new Error('Sign-in failed');
      }
      
      const data = await response.json();
      console.log('Server response:', data); // Log the response to see its structure
      
      const { accessToken, userId } = data;
      if (!accessToken) {
        throw new Error('Invalid token received');
      }
      
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userId', userId); // Save only the user ID to local storage
      setUserData(data); // Update user data in the parent component
      navigate('/userpanel'); // Navigate to UserPanel
    } catch (error) {
      console.error('Error during sign-in:', error);
      setError('Invalid username or password');
    }
  };
  
  return (
    <div className="container-fluid mt-5">
    <div className="row justify-content-center p-3">
    <div className="col-lg-8 col-12 border rounded border-gray p-3 d-flex align-items-center">
    <div className="me-3 d-none d-md-block" style={{ width: '45%', height: '100%' }}>
    <img src="images/photo-signin.jpeg" alt="signin" className="img-fluid w-100 h-100" />
    </div>
    <div style={{ width: '55%' }}>
    <h2>Login</h2>
    <div className="container">
    <h6 className="mb-4">New to EventTop? <Link to="/registration-form">Sign Up</Link></h6>
    </div>
    {error && <div className="alert alert-danger">{error}</div>}
    <form onSubmit={handleSubmit} className="flex-column align-items-center">
    <div className="mb-6">
    <label htmlFor="username" className="form-label">Username</label>
    <input type="text" className="form-control" id="username" name="username" value={formData.username} onChange={handleInputChange} />
    </div>
    <div className="mb-3">
    <label htmlFor="password" className="form-label">Password</label>
    <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleInputChange} />
    </div>
    <div className="d-flex justify-content-between w-100 mb-2">
    <button type="submit" className="btn btn-primary">Sign In</button>
    <a href="#" className="text-decoration-none">Forgot password?</a>
    </div>
    <div className="mt-4 small text-muted">
    By continuing past this page, you agree to the Terms of Use and understand that information will be used as described in our Privacy Policy.
    </div>
    </form>
    </div>
    </div>
    </div>
    </div>
  );
};

export default SignInForm;
  