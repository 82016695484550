// ContactUs.js

import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const ContactUs = () => {
  return (

    <Container className="mt-5">
    <Row className="justify-content-center">
    <Col md={12}>
    <Card className="shadow-lg">
    <Card.Body>

  
    <div class="container">
    <h1>Internship Opportunity: JavaScript, Node.js, Python Flask App Developer</h1>
    <p>
    <strong>EventTop!</strong> (<a href="https://eventtop.ca" target="_blank">eventtop.ca</a>) is seeking a highly motivated and talented individual for an internship position as a JavaScript, Node.js, Python Flask App Developer. This internship offers a unique opportunity to gain hands-on experience and work closely with our experienced development team on cutting-edge projects.
    </p>
    
    <h2>Responsibilities:</h2>
    <ul>
    <li>Collaborate with our development team to design, develop, test, and deploy web applications.</li>
    <li>Work on frontend development using JavaScript and Node.js.</li>
    <li>Contribute to backend development using Python Flask framework.</li>
    <li>Participate in code reviews and provide constructive feedback.</li>
    <li>Troubleshoot and debug applications to ensure optimal performance.</li>
    </ul>
    
    <h2>Qualifications:</h2>
    <ul>
    <li>Currently pursuing a degree in Computer Science, Software Engineering, or a related field.</li>
    <li>Strong understanding of JavaScript, Node.js, and Python Flask.</li>
    <li>Familiarity with web development frameworks and libraries.</li>
    <li>Knowledge of RESTful API design and integration.</li>
    <li>Ability to work independently and as part of a collaborative team.</li>
    <li>Strong problem-solving skills and attention to detail.</li>
    </ul>
    
    <h2>Benefits:</h2>
    <ul>
    <li>Gain practical experience in a professional development environment.</li>
    <li>Mentorship from experienced developers.</li>
    <li>Exposure to real-world projects and challenges.</li>
    <li>Networking opportunities within the tech industry.</li>
    <li>Possibility of a full-time position upon successful completion of the internship.</li>
    </ul>
    
    <h2 class="application">Application Process:</h2>
    <p>
    To apply for this internship, please submit your resume, a cover letter, and a portfolio (if applicable) showcasing relevant projects to <a href="mailto:info@eventtop.ca">info@eventtop.ca</a>. Please include <strong>"Internship Application: JavaScript, Node.js, Python Flask App Developer"</strong> in the subject line.
    </p>
    
    <p>
    <strong>EventTop!</strong> is an equal opportunity employer. We encourage applications from candidates of all backgrounds and experiences.
    </p>
    
    <p>
    Join us in shaping the future of innovative web development!
    </p>

    </div>
    
    
    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>

    
  );
};

export default ContactUs;
  