// EventList.js

import React, { useState, useEffect } from 'react';
import { Card, Button, Col, Row, Pagination } from 'react-bootstrap';
import './EventList.css'; // Custom CSS file for additional styles
import { API_ENDPOINTS } from '../config'; // Adjust the path as necessary

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 8;

  // Fetch events only once when the component mounts
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.events);
        const data = await response.json();
        console.log('Events:', data);
        // Sort events by date (newest first)
        const sortedEvents = data.sort((a, b) => new Date(b.EventDate) - new Date(a.EventDate));
        setEvents(sortedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []); // Empty dependency array ensures this effect runs only once

  // Calculate the current events to display
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  // Calculate the total number of pages
  const totalPages = Math.ceil(events.length / eventsPerPage);

  const handleBuyTickets = (eventID) => {
    window.location.href = `/ticket-store?eventID=${eventID}`;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container-fluid p-3 mx-auto flex-column responsive-container">
      <h2 className="mt-4">Upcoming Events:</h2>
      <Row>
        {currentEvents.slice(0, 3).map(event => {
          const imagePath = event.PosterImagePath
            ? (event.PosterImagePath.startsWith(API_ENDPOINTS.uploads)
              ? `${API_ENDPOINTS.uploads}${event.PosterImagePath}`
              : `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
            )
            : `${API_ENDPOINTS.uploads}/placeholder_image.png`; // Use a default placeholder image if path is missing

          // Format the event date
          const formattedDate = new Date(event.EventDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          });

          return (
            <Col md={12} lg={4} key={event.EventID} className="mb-3">
              <Card className="shadow-sm h-100 position-relative">
                <div className="card-header bg-primary text-white">
                  <h4 className="mb-0">{event.EventName}</h4>
                </div>
                <Row noGutters>
                  <Col md={6}>
                    <Card.Body className="p-3 d-flex flex-column position-static">
                      <Card.Text className="mb-0"><h5>Date/Time:</h5></Card.Text>
                      <Card.Text className="mb-0">{formattedDate}</Card.Text>
                      <Card.Text className="mb-3">{event.StartTime} - {event.EndTime}</Card.Text>
                      <Card.Text className="mb-0"><h5>Location:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.VenueName}</Card.Text>
                      <Card.Text className="mb-3">{event.EventCity}, {event.EventCountry}</Card.Text>
                      <Card.Text className="mb-0"><h5>Theme:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.Theme}</Card.Text>
                    </Card.Body>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <img
                        src={imagePath}
                        alt="Event Poster"
                        className="img-fluid rounded"
                        style={{ maxHeight: '300px', maxWidth: '100%', objectFit: 'cover' }}
                        onError={(e) => { e.target.onerror = null; e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`; }} // Fallback image
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 p-2 d-flex justify-content-center">
                  <Button variant="primary" className="w-75" onClick={() => handleBuyTickets(event.EventID)}>Get Information/Buy Tickets</Button>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Row className="mt-4">
        {currentEvents.slice(3).map(event => {
          const imagePath = event.PosterImagePath
            ? (event.PosterImagePath.startsWith(API_ENDPOINTS.uploads)
              ? `${API_ENDPOINTS.uploads}${event.PosterImagePath}`
              : `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
            )
            : `${API_ENDPOINTS.uploads}/placeholder_image.png`; // Use a default placeholder image if path is missing

          // Format the event date
          const formattedDate = new Date(event.EventDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          });

          return (
            <Col md={6} key={event.EventID} className="mb-3">
              <Card className="shadow-sm h-100 position-relative">
                <div className="card-header bg-secondary text-white">
                  <h4 className="mb-0">{event.EventName}</h4>
                </div>
                <Row noGutters>
                  <Col md={6}>
                    <Card.Body className="p-3 d-flex flex-column position-static">
                      <Card.Text className="mb-0"><h5>Date/Time:</h5></Card.Text>
                      <Card.Text className="mb-0">{formattedDate}</Card.Text>
                      <Card.Text className="mb-3">{event.StartTime} - {event.EndTime}</Card.Text>
                      <Card.Text className="mb-0"><h5>Location:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.VenueName}</Card.Text>
                      <Card.Text className="mb-3">{event.EventCity}, {event.EventCountry}</Card.Text>
                      <Card.Text className="mb-0"><h5>Theme:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.Theme}</Card.Text>
                    </Card.Body>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <img
                        src={imagePath}
                        alt="Event Poster"
                        className="img-fluid rounded"
                        style={{ maxHeight: '400px', maxWidth: '100%', objectFit: 'cover' }}
                        onError={(e) => { e.target.onerror = null; e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`; }} // Fallback image
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 p-2 d-flex justify-content-center">
                  <Button variant="secondary" className="w-75" onClick={() => handleBuyTickets(event.EventID)}>Get Information/Buy Tickets</Button>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Pagination className="mt-4 d-flex justify-content-center">
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      <h2 className="mt-4">Events in Toronto</h2>
      <Row>
        {currentEvents.filter(event => event.EventCity && event.EventCity.toLowerCase() === 'toronto').slice(0, 2).map(event => {
          const imagePath = event.PosterImagePath
            ? (event.PosterImagePath.startsWith(API_ENDPOINTS.uploads)
              ? `${API_ENDPOINTS.uploads}${event.PosterImagePath}`
              : `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
            )
            : `${API_ENDPOINTS.uploads}/placeholder_image.png`; // Use a default placeholder image if path is missing

          // Format the event date
          const formattedDate = new Date(event.EventDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          });

          return (
            <Col md={6} key={event.EventID} className="mb-3">
              <Card className="shadow-sm h-100 position-relative">
                <div className="card-header bg-info text-white">
                  <h4 className="mb-0">{event.EventName}</h4>
                </div>
                <Row noGutters>
                  <Col md={6}>
                    <Card.Body className="p-3 d-flex flex-column position-static">
                      <Card.Text className="mb-0"><h5>Date/Time:</h5></Card.Text>
                      <Card.Text className="mb-0">{formattedDate}</Card.Text>
                      <Card.Text className="mb-3">{event.StartTime} - {event.EndTime}</Card.Text>
                      <Card.Text className="mb-0"><h5>Location:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.VenueName}</Card.Text>
                      <Card.Text className="mb-3">{event.EventCity}, {event.EventCountry}</Card.Text>
                      <Card.Text className="mb-0"><h5>Theme:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.Theme}</Card.Text>
                    </Card.Body>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <img
                        src={imagePath}
                        alt="Event Poster"
                        className="img-fluid rounded"
                        style={{ maxHeight: '400px', maxWidth: '100%', objectFit: 'cover' }}
                        onError={(e) => { e.target.onerror = null; e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`; }} // Fallback image
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 p-2 d-flex justify-content-center">
                  <Button variant="info" className="w-75" onClick={() => handleBuyTickets(event.EventID)}>Buy Tickets</Button>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>

      <h2 className="mt-4">Events in Montreal</h2>
      <Row>
        {currentEvents.filter(event => event.EventCity && event.EventCity.toLowerCase() === 'montreal').slice(0, 2).map(event => {
          const imagePath = event.PosterImagePath
            ? (event.PosterImagePath.startsWith(API_ENDPOINTS.uploads)
              ? `${API_ENDPOINTS.uploads}${event.PosterImagePath}`
              : `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
            )
            : `${API_ENDPOINTS.uploads}/placeholder_image.png`; // Use a default placeholder image if path is missing

          // Format the event date
          const formattedDate = new Date(event.EventDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          });

          return (
            <Col md={6} key={event.EventID} className="mb-3">
              <Card className="shadow-sm h-100 position-relative">
                <div className="card-header bg-success text-white">
                  <h4 className="mb-0">{event.EventName}</h4>
                </div>
                <Row noGutters>
                  <Col md={6}>
                    <Card.Body className="p-3 d-flex flex-column position-static">
                      <Card.Text className="mb-0"><h5>Date/Time:</h5></Card.Text>
                      <Card.Text className="mb-0">{formattedDate}</Card.Text>
                      <Card.Text className="mb-3">{event.StartTime} - {event.EndTime}</Card.Text>
                      <Card.Text className="mb-0"><h5>Location:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.VenueName}</Card.Text>
                      <Card.Text className="mb-3">{event.EventCity}, {event.EventCountry}</Card.Text>
                      <Card.Text className="mb-0"><h5>Theme:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.Theme}</Card.Text>
                    </Card.Body>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <img
                        src={imagePath}
                        alt="Event Poster"
                        className="img-fluid rounded"
                        style={{ maxHeight: '400px', maxWidth: '100%', objectFit: 'cover' }}
                        onError={(e) => { e.target.onerror = null; e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`; }} // Fallback image
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 p-2 d-flex justify-content-center">
                  <Button variant="success" className="w-75" onClick={() => handleBuyTickets(event.EventID)}>Get Information/Buy Tickets</Button>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>

      <h2 className="mt-4">Events in Ottawa</h2>
      <Row>
        {currentEvents.filter(event => event.EventCity && event.EventCity.toLowerCase() === 'ottawa').slice(0, 2).map(event => {
          const imagePath = event.PosterImagePath
            ? (event.PosterImagePath.startsWith(API_ENDPOINTS.uploads)
              ? `${API_ENDPOINTS.uploads}${event.PosterImagePath}`
              : `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
            )
            : `${API_ENDPOINTS.uploads}/placeholder_image.png`; // Use a default placeholder image if path is missing

          // Format the event date
          const formattedDate = new Date(event.EventDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          });

          return (
            <Col md={6} key={event.EventID} className="mb-3">
              <Card className="shadow-sm h-100 position-relative">
                <div className="card-header bg-success text-white">
                  <h4 className="mb-0">{event.EventName}</h4>
                </div>
                <Row noGutters>
                  <Col md={6}>
                    <Card.Body className="p-3 d-flex flex-column position-static">
                      <Card.Text className="mb-0"><h5>Date/Time:</h5></Card.Text>
                      <Card.Text className="mb-0">{formattedDate}</Card.Text>
                      <Card.Text className="mb-3">{event.StartTime} - {event.EndTime}</Card.Text>
                      <Card.Text className="mb-0"><h5>Location:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.VenueName}</Card.Text>
                      <Card.Text className="mb-3">{event.EventCity}, {event.EventCountry}</Card.Text>
                      <Card.Text className="mb-0"><h5>Theme:</h5></Card.Text>
                      <Card.Text className="mb-0">{event.Theme}</Card.Text>
                    </Card.Body>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <img
                        src={imagePath}
                        alt="Event Poster"
                        className="img-fluid rounded"
                        style={{ maxHeight: '400px', maxWidth: '100%', objectFit: 'cover' }}
                        onError={(e) => { e.target.onerror = null; e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`; }} // Fallback image
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 p-2 d-flex justify-content-center">
                  <Button variant="success" className="w-75" onClick={() => handleBuyTickets(event.EventID)}>Get Information/Buy Tickets</Button>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default EventList;
