// CreateEvent.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { API_ENDPOINTS } from '../config';

const EventForm = () => {
    const [formData, setFormData] = useState({
        EventName: '',
        EventType: '',
        UserID: '',
        EventDate: new Date(),
        EventCity: '',
        EventCountry: '',
        StartTime: '',
        EndTime: '',
        VenueName: '',
        VenueAddress: '',
        VenueDetails: '',
        Theme: '',
        DressCode: '',
        ContactEmail: '',
        ContactPhone: '',
        CovidProtocol: '',
        AccessibilityInfo: '',
        PosterImage: null,
        OrganizerName: '',
        SeatMap: null,
    });
    
    const [successMessage, setSuccessMessage] = useState('');
    const [EventType, setEventType] = useState(''); // State to hold selected event type    
    //const [userID, setUserID] = useState('');

    const userId = localStorage.getItem('userId');

    // Handle changes in the input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            UserID: userId,
        }));
        
        //console.log(userId)
        
    };

    const handleDateChange = (date) => {
        setFormData((prevData) => ({ ...prevData, EventDate: date }));
    };
    
    // Handle file change
//  const handleFileChange = (event) => {
//      const file = event.target.files[0];
//      setFormData((prevFormData) => ({
//          ...prevFormData,
//          PosterImage: file,
//      }));
//      //console.log('event.target.files[0]', event.target.files[0]);
//      //console.log('file', file);
//  };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const defaultFileName = "placeholder_image.png";
        
        // Check if a file is provided
        if (file) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                PosterImage: file,
            }));
        } else {
            // If no file is provided, use the placeholder filename
            setFormData((prevFormData) => ({
                ...prevFormData,
                PosterImage: defaultFileName,
            }));
        }
        
        // For debugging purposes
        // console.log('event.target.files[0]', event.target.files[0]);
        // console.log('file', file);
    };
    
    // Log the updated formData.PosterImage value
    useEffect(() => {
        console.log('Updated formData', formData.PosterImage);
    }, [formData.PosterImage]);
    
    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!formData.EventName.trim()) {
            alert('Event Name is required');
            //setSuccessMessage('Event Name is required'); // Update success message
            return;
        }

        if (!formData.VenueName.trim()) {
            alert('Venue Name is required');
            //setSuccessMessage('Event Name is required'); // Update success message
            return;
        }
        
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }


        try {
            //const response = await fetch('http://localhost:3010/create-event', {
            const response = await fetch(API_ENDPOINTS.createEvent, {
                method: 'POST',
                body: data,
            });
            
            if (response.ok) {
                console.log('Event created successfully!');
            } else {
                                
                console.error('Failed to create event.');
            }
            
            setSuccessMessage('Event created successfully'); // Update success message
            
        } catch (error) {
            console.error('Error:', error);
        }

    };
    
    return (
        
        <Container className="mt-5">
        <Row className="justify-content-center">
        <Col md={12}>
        <Card className="shadow-lg p-4">
        <Card.Body>
        

        <div className="card-header">Create Event</div>
        <div className="card-body">
        <form onSubmit={handleSubmit}>
        <div className="form-group">
        <label>Event Name:</label>
        <input
        type="text"
        className="form-control"
        name="EventName"
        value={formData.EventName}
        onChange={handleChange}
        />
        </div>

        <div className="form-group">
        <label>Event Organizer:</label>
        <input
        type="text"
        className="form-control"
        name="OrganizerName"
        value={formData.OrganizerName}
        onChange={handleChange}
        />
        </div>
        

        <div className="form-group">
        <label>Event Type:</label>
        <div className="dropdown">
        <select className="form-control dropdown-select" 
        name="EventType"
        value={formData.EventType} 
        onChange={handleChange}>
        <option value="">Select Event Type</option>
        <option value="general admission">General Admission</option>
        <option value="banquet hall">Banquet Hall</option>
        <option value="conference">Conference</option>
        <option value="festival">Festival</option>
        <option value="seminar">Seminar</option>
        <option value="workshop">Workshop</option>
        <option value="concert">Concert</option>
        <option value="exhibition">Exhibition</option>
        <option value="trade show">Trade Show</option>
        <option value="networking event">Networking Event</option>
        <option value="charity event">Charity Event</option>
        <option value="product launch">Product Launch</option>
        <option value="award ceremony">Award Ceremony</option>
        <option value="wedding">Wedding</option>
        <option value="birthday party">Birthday Party</option>
        <option value="anniversary celebration">Anniversary Celebration</option>
        <option value="fundraising event">Fundraising Event</option>
        <option value="sports event">Sports Event</option>
        </select>
        <div className="dropdown-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
        <path d="M2.646 5.646a.5.5 0 0 1 .708 0L8 10.293l4.646-4.647a.5.5 0 0 1 .708.708l-5 5a.5.5 0 0 1-.708 0l-5-5a.5.5 0 0 1 0-.708z" />
        </svg>
        </div>
        </div>
        </div>

        <div className="form-group">
        <label>Event Date:</label>
        <DatePicker
        selected={formData.EventDate}
        onChange={handleDateChange}
        className="form-control"
        />
        </div>
        <div className="form-group">
        <label>Start Time:</label>
        <input
        type="time"
        className="form-control"
        name="StartTime"
        value={formData.StartTime}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>End Time:</label>
        <input
        type="time"
        className="form-control"
        name="EndTime"
        value={formData.EndTime}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>Event City:</label>
        <input
        type="text"
        className="form-control"
        name="EventCity"
        value={formData.EventCity}
        onChange={handleChange}
        />
        <label>Event Country:</label>
        <input
        type="text"
        className="form-control"
        name="EventCountry"
        value={formData.EventCountry}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>Venue Name:</label>
        <input
        type="text"
        className="form-control"
        name="VenueName"
        value={formData.VenueName}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>Venue Address:</label>
        <input
        type="text"
        className="form-control"
        name="VenueAddress"
        value={formData.VenueAddress}
        onChange={handleChange}
        />
        
        <div className="form-group">
        <label>Does Event Require a Seat Map?</label>
        <div className="dropdown">
        <select className="form-control dropdown-select" 
        name="EventType"
        value={formData.EventType} 
        onChange={handleChange}>
        <option value="">Seat Map Requirement</option>
        <option value="1">Yes</option>
        <option value="0">No</option>
        </select>
        <div className="dropdown-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
        <path d="M2.646 5.646a.5.5 0 0 1 .708 0L8 10.293l4.646-4.647a.5.5 0 0 1 .708.708l-5 5a.5.5 0 0 1-.708 0l-5-5a.5.5 0 0 1 0-.708z" />
        </svg>
        </div>
        </div>
        </div>
        
        </div>
        <div className="form-group">
        <label>Venue Details:</label>
        <textarea
        className="form-control"
        name="VenueDetails"
        value={formData.VenueDetails}
        onChange={handleChange}
        ></textarea>
        </div>
        <div className="form-group">
        <label>Theme:</label>
        <input
        type="text"
        className="form-control"
        name="Theme"
        value={formData.Theme}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>Dress Code:</label>
        <input
        type="text"
        className="form-control"
        name="DressCode"
        value={formData.DressCode}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>Contact Email:</label>
        <input
        type="email"
        className="form-control"
        name="ContactEmail"
        value={formData.ContactEmail}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>Contact Phone:</label>
        <input
        type="tel"
        className="form-control"
        name="ContactPhone"
        value={formData.ContactPhone}
        onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label>COVID Protocol:</label>
        <textarea
        className="form-control"
        name="CovidProtocol"
        value={formData.CovidProtocol}
        onChange={handleChange}
        ></textarea>
        </div>
        
        <div className="form-group">
        <label>Poster Image:</label>
        <input type="file" name="PosterImage" onChange={handleFileChange} />
        </div>        
        
        <div className="form-group">
        <label>Accessibility Info:</label>
        <textarea
        className="form-control"
        name="AccessibilityInfo"
        value={formData.AccessibilityInfo}
        onChange={handleChange}
        ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Create Event</button>
        {/* Conditionally render success message */}
        {successMessage && (
            <div className="alert alert-success mt-3" role="alert">
            {successMessage}
            </div>
        )}
        </form>
        </div>
        
        
        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>
        
    );


};

export default EventForm;
    