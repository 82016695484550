// src/config.js
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3010';

export const API_ENDPOINTS = {
  eventSearch: `${API_URL}/eventsearch`,
  getUser: (userId) => `${API_URL}/user/${userId}`,
  register: `${API_URL}/register`,
  login: `${API_URL}/login`,
  register: `${API_URL}/register`,
  events: `${API_URL}/events`,
  getEvent: (eventID) => `${API_URL}/events/${eventID}`,
  PLACEHOLDER: '/uploads/placeholder_image.png',
  getTickets: (userId) => `${API_URL}/user/${userId}/tickets`,
  organizedEvents: (userId) => `${API_URL}/user/${userId}/organized-events`,
  createEvent: `${API_URL}/create-event`,
  uploads: `${API_URL}/uploads`,
  // Add other endpoints here
};


